@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,700;1,8..144,800;1,8..144,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
    --default-font_family: "Inter", sans-serif;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--default-font_family);
    color: inherit;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 h6 {
    font-weight: normal;
}

img {
    display: block;
    max-width: 100%;

}

body {
    margin: 0;
    padding: 0;


}

.ant-form-item {
    margin-bottom: 16px;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
